// @flow
import { useRef, useEffect, } from 'react';

export default function useOneTime(condition: boolean, cb: Function): any {
  const ref = useRef(false);
  useEffect(() => {
    if (!ref.current && condition && typeof cb === 'function') {
      ref.current = true;
      const result = cb();
      if (typeof result === 'function') {
        return result;
      }
    }
    return () => { };
  }, [ condition, cb, ]);
}
