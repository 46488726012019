// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import BasePlaceholder from '../BasePlaceholder/BasePlaceholder';

function wrapperStyles({ theme, }) {
  return {
    display: 'grid',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    ...theme.mq(
      { until: 's', },
      { gridGap: '2rem', marginTop: '6rem', padding: '0 2rem', }
    ),
    ...theme.mq(
      { from: 's', until: 'l', },
      { gridTemplateColumns: 'repeat(2, 1fr)', }
    ),
    ...theme.mq(
      { from: 's', },
      { gridGap: '4rem', marginTop: '8rem', padding: '0 4rem', }
    ),
    ...theme.mq({ from: 'l', }, { gridTemplateColumns: 'repeat(4, 1fr)', }),
    ...theme.mq({ from: 's', until: 'm', }, { maxWidth: 100, }),
    ...theme.mq({ from: 'm', until: 'l', }, { maxWidth: 768 / 6, }),
    ...theme.mq({ from: 'l', until: 'xl', }, { maxWidth: 1024 / 6, }),
    ...theme.mq({ from: 'xl', }, { maxWidth: (1280 - 17) / 7, }),
  };
}
function headerStyles({ theme, }) {
  return {
    alignSelf: 'start',
    height: '6rem',
    position: 'relative',
    width: '100%',
    ...theme.mq({ until: 'l', }, { gridColumnEnd: 'span 2', }),
    ...theme.mq({ from: 'l', }, { gridRowEnd: 'span 2', }),
  };
}
function item1Styles({ theme, }) {
  return {
    position: 'relative',
    width: '100%',
    ...theme.mq({ until: 's', }, { height: '50rem', }),
    ...theme.mq({ until: 'l', }, { gridColumnEnd: 'span 2', }),
    ...theme.mq({ from: 'l', }, { gridRowEnd: 'span 2', }),
  };
}
function itemsStyles({ theme, }) {
  return {
    height: '30rem',
    position: 'relative',
    width: '100%',
    ...theme.mq({ until: 's', }, { display: 'none', }),
  };
}

// let wrapperClasses: ?string;
// let headerClasses: ?string;
// let item1Classes: ?string;
// let itemsClasses: ?string;

function ListPlaceholder() {
  const { css, } = useFela();

  const wrapperClasses = css(wrapperStyles);
  const headerClasses = css(headerStyles);
  const item1Classes = css(item1Styles);
  const itemsClasses = css(itemsStyles);

  return (
    <div className={wrapperClasses}>
      {/* header */}
      <div className={headerClasses}>
        <BasePlaceholder isAbsolute />
      </div>

      {/* item 1 */}
      <div className={item1Classes}>
        <BasePlaceholder isAbsolute />
      </div>
      {/* item 2 */}
      <div className={itemsClasses}>
        <BasePlaceholder isAbsolute />
      </div>
      {/* item 3 */}
      <div className={itemsClasses}>
        <BasePlaceholder isAbsolute />
      </div>
      {/* item 4 */}
      <div className={itemsClasses}>
        <BasePlaceholder isAbsolute />
      </div>
      {/* item 5 */}
      <div className={itemsClasses}>
        <BasePlaceholder isAbsolute />
      </div>
    </div>
  );
}
function neverRerender() {
  return true;
}
export default React.memo<any>(ListPlaceholder, neverRerender);
