import React from 'react';
import gql from 'graphql-tag';

import Query from '../ApolloBoundary/Query';


export const getA11yState = gql`
  query GetA11yStatus {
    a11yToggle @client
  }
`;

function withA11yState(Component) {
  return function WithA11yState(props) {
    return (
      <Query query={getA11yState}>
        {({ loading, error, data, }) => (
          <Component
            a11yState={data ? data.a11yToggle : false}
            {...props}
          />
        )}
      </Query>
    );
  };
}

export default withA11yState;
