/* eslint-disable react/require-default-props */
// @flow
import React from 'react';
import { LevelConsumer, } from './LevelContext';

type HProps = {
  /**
   * The offset from the calculated heading level.
   * e.g: the calculated level is 3 and the offset is 1, the heading level will be 4.
   * The offset can be negative.
   * The final Heading level can be between 2 and 6,
   * e.g the calculated level is 10, the heading level will be 6.
   */
  offset?: number,
  /** Forces the generated element to be an H1 */
  isH1?: boolean,
};

// $FlowFixMe
const H = React.forwardRef<HProps>(({ offset = 0, isH1 = false, ...props }: HProps, ref) => (
  <LevelConsumer>
    {level => {
      const hLevel = isH1
        ? 1
        : Math.max(Math.min(Math.round(level + offset), 6), 2);
      const Heading = `h${hLevel}`;
      return <Heading {...props} ref={ref} />;
    }}
  </LevelConsumer>
));

export default H;
