const transformPrefixes = {
  height: 'height=',
  width: '&width=',
  frame: '&frame=',
};

const getCropQS = cropData => `precrop=${cropData.width && `${cropData.width},`}${cropData.height && `${cropData.height},`}x${cropData.x || 0},y${cropData.y || 0}&`;

const userTransformsReducer = (allTransforms, [ propName, value, ]) => {
  const prefix = transformPrefixes[propName];
  const transfromString = prefix ? prefix + value : '';
  return prefix
    ? `${allTransforms}${transfromString}`
    : allTransforms;
};

export default function buildlImgURL({
  contentId,
  imgName,
  aspects,
  baseUrl,
  settings,
  env,
}) {
  if (typeof imgName === 'string' && /^https?:\/\//i.test(imgName)) {
    // imgName is abslute url
    return imgName;
  }

  const cropData = aspects[settings.aspect];
  const cropQS = cropData ? getCropQS(cropData) : '';
  const userTransforms = Object.entries(settings).reduce(userTransformsReducer, '');

  const url = `${baseUrl}/${contentId}/${imgName}?${cropQS}${userTransforms}${env ? `&cms${env}` : ''}`;

  const isLegacyImg = /^[1,7]\.\d+$/.test(contentId);

  if (isLegacyImg) {
    return url
      .replace('/bs/', '/img/')
      .replace('/image/', '/');
  }

  return url;
}
