// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';


type Props = { isHidden: boolean, isAbsolute: boolean, };
BasePlaceholder.defaultProps = { isHidden: false, isAbsolute: false, };
export default function BasePlaceholder({
  isHidden,
  isAbsolute,
}: Props): React.Node {
  const { css, theme, } = useFela();
  const className = css({
    backgroundColor: theme.color('placeholder', 'bg'),
    bottom: isAbsolute ? '0' : null,
    left: isAbsolute ? '0' : null,
    overflow: 'hidden',
    position: isAbsolute ? 'absolute' : 'relative',
    right: isAbsolute ? '0' : null,
    top: isAbsolute ? '0' : null,

    '&:before': {
      animationDirection: 'forwards',
      animationIterationCount: 'infinite',
      ...theme.getDuration('animation', 10),
      animationName: [
        { '0%': { transform: 'translateX(-100%)', }, },
        { '35%': { transform: 'translateX(100%)', }, },
        { '100%': { transform: 'translateX(150%)', }, },
      ],
      backgroundImage: `linear-gradient(90deg, ${theme.color(
        'neutral',
        '-7'
      )} 0, ${theme.color('bg', 'base')} 45%, ${theme.color('placeholder', 'bg')} 65%)`,
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      top: '0',
      transform: 'translateX(-100%)',
      willChange: 'transform',
    },
  });

  return isHidden ? null : <div className={className} />;
}
