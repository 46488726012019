import { useRouter, } from 'next/router';
import isQueryValueTrue from '../utils/isQueryValueTrue';

export function useIsBot() {
  const router = useRouter();

  if (!router || !router.query) return { isBot: false, bot: undefined, };

  const { bot, } = router.query || {};

  const isBot = isQueryValueTrue(router.query.isBot);

  return { bot, isBot, };
}

export default useIsBot;
