import * as React from 'react';
import PropTypes from 'prop-types';

NoSSR.propTypes = {
  /**
   * Nodes rendered inside `NoSSR`.
   * Passed to the underlying react element
   */
  children: PropTypes.node,
  /** Placeholder JSX to render on the server */
  onSSR: PropTypes.node,
  /** Placeholder component to render on the server */
  Placeholder: PropTypes.func,
};

NoSSR.defaultProps = {
  children: null,
  onSSR: null,
  Placeholder: null,
};

export default function NoSSR({ children, onSSR, Placeholder, }) {
  const [ shouldRender, setShouldRender, ] = React.useState(false);
  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  if (shouldRender) return children;

  return Placeholder ? <Placeholder /> : onSSR;
}
