// @flow
/* global window */
import { useRouter, } from 'next/router';
import useArticleType from './useArticleType';
import isQueryValueTrue from '../../utils/isQueryValueTrue';

const articleTypesWithPrint = new Set([
  'standardArticle',
  'magazineArticle',
]);

export default function usePrint() {
  const articleType = useArticleType();
  const router = useRouter();

  const isPrint = isQueryValueTrue(router.query.print);

  const isPrintByArticleType = isPrint && articleTypesWithPrint.has(articleType);

  return { isPrint, isPrintByArticleType, };
}
