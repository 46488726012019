/* global window */
// @flow
import * as React from 'react';
import Router from 'next/router';

const routerEventName = 'routeChangeComplete';
let isEventListenerAttached = false;
const observedParams = new Map();
const observedParamsListeners = new Map();
let urlSearchParams = null; // DO NOT ACCESS THIS VAR DIRECTLY! ONLY USE 'getUrlSearchParams' METHOD.

type RouterMethodType = 'push' | 'replace';

/**
 * @returns {URLSearchParams} the url search params of the window (only on browser. else returns null)
 */
function getUrlSearchParams() {
  if (typeof window !== 'undefined' && urlSearchParams === null) {
    urlSearchParams = new URLSearchParams(window.location.search);
  }

  return urlSearchParams;
}

/**
 * Sets the Next.js router event listener if any query-params observed
 */
function setUrlChangesEventListener(): void {
  if (!isEventListenerAttached && observedParams.size > 0) {
    Router.events.on(routerEventName, updateUrlSearchParams);
    Router.events.on(routerEventName, onRouteChange);
    isEventListenerAttached = true;
  }
}
/**
 * Removes the Next.js router event listener if no query-params observed
 */
function removeUrlChangesEventListener(): void {
  if (isEventListenerAttached && observedParams.size === 0) {
    Router.events.off(routerEventName, updateUrlSearchParams);
    Router.events.off(routerEventName, onRouteChange);
    isEventListenerAttached = false;
  }
}
/**
 * Subscribe a query-param listener.
 * @param {!string} paramName The query-param to unsubscribe from
 * @param {!function} paramListener The listener to remove
 */

function observeParam(paramName: string, paramListener: (?string) => void): ?string {
  if (!observedParams.has(paramName)) {
    const paramValue = getQueryParam(paramName);
    observedParams.set(paramName, paramValue);
    observedParamsListeners.set(paramName, []);
  }

  const listeners = observedParamsListeners.get(paramName) || [];
  listeners.push(paramListener);
  observedParamsListeners.set(paramName, listeners);

  setUrlChangesEventListener();
}
/**
 * Unsubscribe a query-param listener.
 * @param {!string} paramName The query-param to unsubscribe from
 * @param {!function} paramListener The listener to remove
 */

function removeObserveParam(paramName: string, paramListener: ?(string) => void): void {
  let observedParamListeners = observedParamsListeners.get(paramName);
  observedParamListeners = observedParamListeners
    ? observedParamListeners.filter(el => el !== paramListener)
    : [];

  if (observedParamListeners.length === 0) {
    observedParams.delete(paramName);
    removeUrlChangesEventListener();
  }
}

/**
 * Next-router 'route-change' event handler
 * Updates the internal 'urlSearchParams'
 */
function updateUrlSearchParams() {
  if (typeof window !== 'undefined') {
    urlSearchParams = new URLSearchParams(window.location.search);
  }
}

/**
 * Next-router 'route-change' event handler
 */
function onRouteChange() {
  const queryParams = getUrlSearchParams();
  if (queryParams != null) {
    observedParams.forEach((storedValue, paramName) => {
      const paramValue = queryParams.get(paramName);
      if (paramValue !== storedValue) {
        observedParams.set(paramName, paramValue);
        const listeners = observedParamsListeners.get(paramName) || [];
        listeners.forEach(listener => listener(paramValue));
      }
    });
  }
}

/**
 * Gets a query-param value by name.
 * @param {!string} paramName The param name retrieve.
 * @returns {string} value of url query-param
 */
function getQueryParam(paramName: string): ?string {
  let paramValue = null;
  if (typeof window === 'undefined') {
    paramValue = null;
  }

  const queryParams = getUrlSearchParams();
  if (queryParams) {
    paramValue = queryParams.get(paramName);
  }
  return paramValue;
}

function createQueryParamSetter(paramName: string, method: ?RouterMethodType = 'push'): (?string) => boolean {
  return (newValue: ?string) => {
    let isParamChanged: boolean = false;

    const asPath = window.location.pathname;
    const asPathQuery = getUrlSearchParams();

    if (asPathQuery) {
      const oldValue = asPathQuery.get(paramName);

      if (newValue !== oldValue) {
        if (!newValue) {
          asPathQuery.delete(paramName);
        }
        else {
          asPathQuery.set(paramName, newValue || '');
        }
        const { pathname, query, } = Router;
        let queryString = asPathQuery.toString();
        queryString = queryString.length > 0 ? `?${queryString}` : '';

        if (typeof Router[method] === 'function') {
          Router[method]({ pathname, query, }, `${asPath}${queryString}`, { shallow: true, });
          isParamChanged = true;
        }
      }
    }

    return isParamChanged;
  };
}

/**
 * Hook for registering to query-param changes
 * @param {!string} paramName The query-param to subscribe to
 * @returns {[ string, function ]} Array that contains value of url query-param and a param-setter function
 */
function useQueryParam(
  paramName: string,
  method: ?RouterMethodType
): [?string, (?string) => boolean, ] {
  if (!paramName) {
    throw new Error('paramName is required.');
  }

  const [ param, setParam, ] = React.useState(getQueryParam(paramName));
  const setQueryParam = React.useCallback(
    createQueryParamSetter(paramName, method), [ paramName, method, ]
  );

  React.useEffect(() => {
    let paramListener = null;

    paramListener = newValue => {
      setParam(newValue);
    };
    observeParam(paramName, paramListener);

    return () => removeObserveParam(paramName, paramListener);
  }, [ paramName, ]);

  // There is a bug in the implementation of `useCallback` in flow 0.87.0
  // the blow fixme should be removed once we upgrade.
  // $FlowFixMe
  return [ param, setQueryParam, ];
}

export default useQueryParam;
