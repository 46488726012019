import config from 'config';
import { aspectRatios as ratiosForCalc, } from '../components/Image/consts';
import buildImgUrl from './buildFastlyImgUrl';


const aspectRatios = Object.entries(ratiosForCalc)
  .reduce((calculatedRatios, [ aspect, { width, height, }, ]) => ({
    ...calculatedRatios,
    [aspect]: width / height,
  }), {});

/**
 * @typedef {Object} Aspect
 *   An object describing an image's basic crop attributes for
 *   a given aspect ratio as defined in the CMS.
 * @prop {string} x
 *   The horizontal coordinate, from the left, from which to start the crop.
 * @prop {string} y
 *   The vertical coordinate, from the top, from which to start the crop.
 * @prop {string} width
 *   The number of pixels to include in the cropped image, starting from `x` to the right.
 * @prop {string} height
 *   The number of pixels to include in the cropped image, starting from `y` to the bottom.
 */

/**
 * An object containing basic data about the image,
 * as obtained from, for example, the Apollo store
 * @typedef {Object} Data
 * @prop {Object} aspects
 *   each aspect is an object has width,height,x and y keys
 * @prop {Aspect} aspects.full
 *   The image's crop information in the `landscape` aspect ratio
 * @prop {Aspect} [aspects.landscape]
 *   The image's crop information in the `landscape` aspect ratio
 * @prop {Aspect} [aspects.regular]
 *   The image's crop information in the `regular` aspect ratio
 * @prop {Aspect} [aspects.headline]
 *   The image's crop information in the `headline` aspect ratio
 * @prop {Aspect} [aspects.square]
 *   The image's crop information in the `square` aspect ratio
 * @prop {Aspect} [aspects.vertical]
 *   The image's crop information in the `vertical` aspect ratio
 * @prop {Aspect} [aspects.belgrade]
 *   The image's crop information in the `belgrade` aspect ratio
 * @prop {string} imgName
 *   the image name
 */

/**
 * @typedef {Object} Options
 * @property {string} width
 *   The width, in pixels, of the image the generated url points to.
 *   The is equivalent to the w descriptor in the srcset attribute.
 * @property {string} [height]
 *   The height of the file returned from the url. Automatically
 *   determined by the aspect when not passed.
 * @property {'full'|'landscape'|'regular'|'headline'|'square'|'vertical'|'belgrade'} [aspect=full]
 *   The image's aspect ratio
 * @property {string} [quality='auto'] -  The image quality
 * @property {boolean} [isProgressive=false] - Generate a progressive jpeg
 * @property {string[]} [transforms]
 *   An array of strings with additional transforms to apply to the image url
 * @property {string[]} [flags] - An array of additional flags to apply to the image
 */

/**
 * @typedef {boolean} ExcludeWidthDescriptor -
 *   when excludeWidthDescriptor is true the function
 *   will omit width descriptor and when false or not exist
 *   the function automatically adds the width descriptor at
 *   the end of the url string
 */

/**
 * A function that takes an image's `contentId`, data about the image and usage options.
 * Returns an array of urlStrings (for `srcSet`).
 * @param contentId
 *  the image's Polopoly contentId
 * @param {Data} data - data properties (@link Data)
 * @param {Options[]} options - An array of options properties
 *  an array of image rendering options, each eventually translated into a url-string in the returned array.
 *  Multiple objects are useful for generating values to be used in the srcSet attributes of an img or source
 * @param {ExcludeWidthDescriptor} excludeWidthDescriptor
 * @return {string[]} - an array of img urls
 * @example
 * // Single item in the options array returns an array with a single url-string
 * buildURLS(1.4444, imageData, [ { width: '100', }, ]); // returns [ <urlString>, ]
 *
 * // Multiple items in the `options` array returns
 * // an array with a url-string for each item in `options`
 * buildURLS(
 *   1.4444,
 *   imageData,
 *   [ { width: '100', }, { width: '200', quality: '.6', }, ]
 * ); // returns [ <urlString>, <urlString>, ]
 */
export function buildURLs(
  contentId,
  data,
  options,
  {
    excludeWidthDescriptor = false,
    isAnimationReduced = false,
  } = {}
) {
  return options.map(
    imgOption => `${buildUrl(contentId, data, imgOption, isAnimationReduced)}${
      !excludeWidthDescriptor ? ` ${imgOption.width}w` : ''
    }`
  );
}

/**
 * Build an image's url-string based on a contentId, data about the image and usage options.
 * @param contentId
 *  the image's Polopoly contentId
 * @param {Data} data - see properties here (@link Data)
 * @param {Options} options - see properties here (@link Options)
 *  an object of image rendering options, each eventually translated into a url-string .
 * @return  'string' , single url string based on the arguments passed to the function.
 *   @example:
 *   //single url-string
 *   buildUrl(
 *     1.4444,
 *     imageData,
 *     { width: '100', }
 *  );
 * // returns  <urlString>
 */

const polopolyImageBaseHref = config.has('service.polopolyImageBaseHref')
  ? config.get('service.polopolyImageBaseHref')
  : null;
// const isDev = typeof polopolyImageBaseHref === 'string' && /^https?:\/\/pre\./.test(polopolyImageBaseHref);

const envs = Object.freeze({
  dev: 'stg',
  stage: 'stg',
  qa: 'qa',
});

export function buildUrl(contentId, data, options = {}, isAnimationReduced = false) {
  const { imgName, aspects, } = data;
  // should only be used in magazine headline
  const isVideo = options.format === 'mp4';
  const isGif = !isVideo && /\.gif($|\s*$|\?)/i.test(imgName);
  const isAnimated = isVideo || (isGif && !isAnimationReduced);
  const useFastlyFormat = !isAnimated;

  const urlPropNameInConfig = useFastlyFormat ? 'service.fastlyImage' : 'service.image';
  const baseUrl = config.has(urlPropNameInConfig) && config.get(urlPropNameInConfig);

  // Fail early when mandatory options aren't present.
  // eslint-disable-next-line eqeqeq
  if (options.width == undefined) {
    throw new Error(
      'width is a mandatory option property for rendering image urls'
    );
  }

  if (!baseUrl) {
    throw new Error(
      'Your app\'s "imgBaseUrl" is not configured.\n'
        + 'See https://github.com/Haaretz/htz-frontend/blob/master/docs/Configuration.md'
    );
  }

  if (!polopolyImageBaseHref) {
    throw new Error(
      'Your app\'s "polopolyImageBaseHref" is not configured.\n'
        + 'See https://github.com/Haaretz/htz-frontend/blob/master/docs/Configuration.md'
    );
  }

  // Augment defaults with user-defined options
  const settings = {
    aspect: 'full',
    isProgressive: false,
    quality: 'auto',
    height: computeHeight(options, options.aspect || 'full', aspects),
    ...(isGif && isAnimationReduced
      ? { frame: 1, }
      : {}),
    ...options,
  };

  const env = config.has('connectionPreset') ? config.get('connectionPreset') : undefined;

  return buildImgUrl({
    contentId,
    imgName,
    aspects,
    baseUrl,
    settings,
    env: envs[env],
  });
}

function computeHeight(options, aspect, aspects) {
  const fullAspect = aspects?.full || options;
  const width = options.width;
  const isFull = aspect === 'full';
  const scaleRatio = (isFull ? fullAspect.width : width) / width;

  const result = Math.round(
    isFull
      ? fullAspect.height / scaleRatio
      : width / aspectRatios[aspect]
  );

  return result;
}
