// @flow
import { useQuery, } from 'react-apollo';

import isAnimationReducedQuery from './queries/isAnimationReduced.graphql';

export default function useIsAnimationReduced(): boolean {
  const { data, } = useQuery(isAnimationReducedQuery);

  return (data || {}).isAnimationReduced || false;
}
