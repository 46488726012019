import * as React from 'react';
import { InView, } from 'react-intersection-observer';
import log from 'loglevel';
import Debug from '../components/Debug/Debug';
import NoSSR from '../components/NoSSR/NoSSR';
import useMedia from '../hooks/useMedia';
import ListPlaceholder from '../components/ListPlaceholder/ListPlaceholder';
import { useUser, } from '../components/User/UserDispenser';
import useWebViewChecker from '../hooks/useWebViewChecker';

export default function getElementsFactory(
  elements,
  {
    DefaultComponent = Default,
    DefaultPlaceHolder = ListPlaceholder,
    defaultRootMargin = 1000,
  } = {}
) {
  return function getElements(
    identifier,
    {
      loadPriority = 'ssr',
      preventRender = null,
      isExpanded = false,
      rootMargin = defaultRootMargin,
      Placeholder = DefaultPlaceHolder,
      excludePlatform = null,
      excludeUserTypes = null,
      contentId,
    } = {}
  ) {
    const priority = isExpanded ? 'ssr' : loadPriority;
    const InnerComponent = elements.get(identifier) || DefaultComponent;

    const hasPreventRender = (preventRender?.length || excludePlatform?.length || excludeUserTypes?.length) > 0;

    if (priority === 'lazy') {
      return function LazyLoadedComponent(props) {
        return hasPreventRender ? (
          <PreventRender
            excludePlatform={excludePlatform}
            excludeUserTypes={excludeUserTypes}
            preventRender={preventRender}
          >
            <InView rootMargin={`${rootMargin}px`} triggerOnce>
              {({ ref, inView, }) => (
                <div ref={ref} style={{ display: 'grid', }}>
                  {inView ? <InnerComponent {...props} /> : <Placeholder />}
                </div>
              )}
            </InView>
          </PreventRender>
        ) : (
          <InView rootMargin={`${rootMargin}px`} triggerOnce>
            {({ ref, inView, }) => (
              <div ref={ref} style={{ display: 'grid', }}>
                {inView ? <InnerComponent {...props} /> : <Placeholder />}
              </div>
            )}
          </InView>
        );
      };
    }
    if (priority === 'client') {
      const ClientWrapper = preventRender?.length ? PreventRender : NoSSR;
      return function ClientSideComponent(props) {
        return (
          <ClientWrapper
            excludePlatform={excludePlatform}
            excludeUserTypes={excludeUserTypes}
            preventRender={preventRender}
            Placeholder={Placeholder}
          >
            <InnerComponent {...props} />
          </ClientWrapper>
        );
      };
    }

    if (hasPreventRender) {
      return function SsrComponent(props) {
        return (
          <PreventRender
            excludePlatform={excludePlatform}
            excludeUserTypes={excludeUserTypes}
            preventRender={preventRender}
          >
            <InnerComponent {...props} />
          </PreventRender>
        );
      };
    }

    return InnerComponent;
  };
}

// ////////////////////////////////////////////////////////////////// //
//                          Inner Components                          //
// ////////////////////////////////////////////////////////////////// //

/* eslint-disable react/prop-types */
function Default({ inputTemplate, contentId, kind, listData, ...props }) {
  const elementType = kind || inputTemplate || (listData && listData.view);
  const elementContentId = contentId || (listData && listData.contentId);

  if (process.env.NODE_ENV !== 'production') {
    log.warn(`
      Element of type: '${elementType}' is not supported
      in this page and we don't have any component for it yet.

      The id of the element you tried to render on this page is: ${elementContentId}.
    `);
  }
  return (
    <Debug>
      <p>{`${elementType} is currently not supported in this page`}</p>
    </Debug>
  );
}

const matchesMap = new Map([
  // [ 'XS', { until: 'xs', }, ], // TODO: breakpoint doe's not exist, see the file bps.js
  // [ 'S', { from: 'xs', until: 's', }, ],
  [ 'S', { until: 's', }, ],
  [ 'M', { from: 'm', until: 'l', }, ],
  [ 'L', { from: 'l', until: 'xl', }, ],
  [ 'XL', { from: 'xl', }, ],
  // [ 'XL', { from: 'xl', until: 'xxl', }, ],
  // [ 'XXL', { from: 'xxl', }, ], // TODO: breakpoint doe's not exist, see the file bps.js
]);

export function useExcludeResponsiveRender(preventRender) {
  const breakpoints = (preventRender || []).reduce((acc, breakpoint) => {
    const match = matchesMap.get(breakpoint);

    if (match) { acc.push(match); }

    return acc;
  }, []);
  const matches = useMedia({ query: breakpoints, });

  if (!preventRender?.length) return true;

  return !matches;
}

export function usePlatformRender(excludePlatform) {
  const isWebView = useWebViewChecker();

  if (Array.isArray(excludePlatform) && excludePlatform.length > 0) {
    if (excludePlatform.includes('app') && isWebView) return false;
    if (excludePlatform.includes('web') && !isWebView) return false;
  }

  return true;
}


const defaultUser = { user: { type: 'anonymous', }, };

export function useExcludeUserTypes(excludeUserTypes) {
  const { user, } = useUser();

  if (Array.isArray(excludeUserTypes) && excludeUserTypes.length > 0) {
    const userType = (user || defaultUser.user).type || defaultUser.user.type;
    const shouldRender = !excludeUserTypes.every(type => type === userType);

    return shouldRender;
  }

  return true;
}


/* eslint-enable react/prop-types */
function PreventRender({ preventRender, excludePlatform, excludeUserTypes, children, }) {
  const appearByMQ = useExcludeResponsiveRender(preventRender);
  const appearByPlatform = usePlatformRender(excludePlatform);
  const appearByUserType = useExcludeUserTypes(excludeUserTypes);

  const isRender = appearByMQ && appearByPlatform && appearByUserType;

  return isRender ? children : null;
}
