// @flow
/* globals window */
import { useState, useEffect, } from 'react';
import { useFela, } from 'react-fela';
import type { MqOptions, } from '@haaretz/htz-css-tools';

import hasMatchMedia from '../utils/hasMatchMedia';

type Params = {
  query: string | MqOptions | Array<MqOptions>,
  matchOnServer?: boolean,
};

export default function useMedia({
  query,
  matchOnServer = false,
}: Params = {}): boolean {
  const { theme, } = useFela();
  const [ matches, setMatches, ] = useState(matchOnServer);
  const mq = typeof query === 'string'
    ? query
    : Array.isArray(query)
      ? query.reduce(
        (rules, rule) => (rules
          ? `${rules},${theme.getMqString(rule, true)}`
          : theme.getMqString(rule, true)),
        ''
      )
      : theme.getMqString(query, true);

  useEffect(() => {
    if (!hasMatchMedia()) return undefined;

    const mqList = window.matchMedia(mq);
    let active = true;

    const listener = (event: MediaQueryListEvent): void => {
      if (!active) return undefined;
      return setMatches(event.matches);
    };

    // Listen to changes
    mqList.addListener(listener);

    // Set `matches` on initial run, before any
    // changes are reported by the listener
    setMatches(mqList.matches);

    return () => {
      active = false;
      mqList.removeListener(listener);
    };
  }, [ mq, query, ]);

  return matches;
}
