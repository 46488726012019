/* eslint-disable max-len */
/* global document */
// @flow
import { useEffect, } from 'react';

// $FlowFixMe
export default function useDocumentEventListener(eventName: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) {
  useEffect(() => {
    document.addEventListener(eventName, listener, options);
    return () => {
      document.removeEventListener(eventName, listener, options);
    };
  }, [ eventName, listener, options, ]);
}
