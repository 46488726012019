// @flow

import { useRouter, } from 'next/router';

export default function useArticleType() {
  const router = useRouter();

  const { articleType, } = router.query || {};

  return articleType || 'not-article';
}
