/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery, } from '@apollo/react-hooks';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

function Query({ children, query, ...options }) {
  const queryResponse = useQuery(query, options);

  return children(queryResponse);
}

const QueryErrorBoundary = props => (
  <ErrorBoundary>
    <Query {...props} />
  </ErrorBoundary>
);

export default QueryErrorBoundary;
