import { useRouter, } from 'next/router';
import usePreview from './usePreview';
import { useIsBot, } from '../useIsBot';


const useLiveBlogQueryVariables = articleId => {
  const router = useRouter();
  const { previewId, } = usePreview();
  const { isBot, } = useIsBot();

  const { asPath, query, } = router;

  if (!(articleId || previewId)) {
    return {};
  }

  const regex = /\/ty-article-live\//;
  const containsTyArticleLive = regex.test(asPath);
  const liveBlogItemId = query.liveBlogItemId;
  const skinSectionName = query.skinSectionName;


  const queryVariables = {
    id: previewId || articleId,
  };

  if (containsTyArticleLive && !isBot) {
    queryVariables.liveBlogItemsCount = liveBlogItemId ? 1 : 10;
    if (liveBlogItemId) {
      queryVariables.liveBlogItemId = liveBlogItemId;
    }
  }

  if (skinSectionName && queryVariables.id) {
    queryVariables.skinSectionName = skinSectionName;
  }

  return queryVariables;
};

export default useLiveBlogQueryVariables;
