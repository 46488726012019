import config from 'config';

export default function checkSiteFromConfig() {
  const siteNumber = config.has('siteNumber') ? config.get('siteNumber') : 80;

  const isTM = siteNumber === 10 || siteNumber === 20;
  const isHDC = siteNumber === 85;
  const isHTZ = !isTM && !isHDC;

  const siteAlias = siteNumber === 10 || siteNumber === 20
    ? 'tm'
    : siteNumber === 85
      ? 'hdc'
      : 'htz';


  return {
    isHTZ,
    isTM,
    isHDC,
    siteNumber,
    siteAlias,
  };
}
